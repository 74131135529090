body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  scroll-behavior: smooth;
}

header a{
   text-decoration: none;
}
a,
a:hover,
a:visited,
a:link,
a:active {
  color:#666;
}

.bannerbox a,
.bannerbox a:hover,
.bannerbox a:visited,
.bannerbox a:link,
.bannerbox a:active {
  color:#fff;
}