.header{
    border-bottom: solid 1px #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    height: 60px;
    z-index: 9;
}
.header .leftBox{
    display: flex;
    align-items: center;
}
.header .logo{
  width: 56px;
  height: auto;
  position: relative;
  top: 2px;
}

.divider{
  height: 40px;
  width: 1px;
  background-color: #666;
  margin: 0 12px;
}

.header p{
  font-size: 12px;
  color: #666;
  margin: 2px;
}
.nav{
  display: flex;
  background-color: #fff;
}
.nav P{
    font-size: 16px;
    padding: 0 18px;
    text-align: center;
    line-height: 61px;
    margin: auto;
    cursor: pointer;
    letter-spacing: 2px;
    box-sizing: border-box;
}
.nav P.active{
      background-color: #333;
    color: #fff;
}

.common-box{
  width: 1200px;
  margin: auto;
}

.page1{
  color: #666;
  padding: 60px 105px 80px 105px;
  box-sizing: border-box;
  text-align: justify;
  min-height: calc(100vh);
  display: flex;
}

.page1 h1{
  width: 100%;
    text-align: center;
    color: #333;
    margin-bottom: 36px;
    font-size: 42px;
    letter-spacing: 3px;
}

.page1 .marginSmall{
  margin-bottom: 8px;
}

.page1 h2{
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  text-align: center;
  color: #888;
  margin-bottom: 50px;
  margin-top: 24px;
}
.page1 .textUl{
      margin: 0;
      margin-top: 10px;
}
.page1 .textUl>li{
  width: 100%;
    border-radius: 3px;
    line-height: 32px;
    font-size: 16px;
    color: #666;
}
.page1 .olmedia{
  font-size: 16px;
  text-align: left;
  margin: 16px auto;
  color: #333;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page1 .olmedia>li{
  width: 100%;
  border-radius: 3px;
  padding: 12px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 24px;
  box-shadow: 5px 5px 8px #f8f8f8;
}


.page1 .olmedia>li{
  width: 46%;
}

.page1 .olmedia>li>span{
  display: block;
  margin-top: 4px;
  color: #888;
  width: 100%;
}

.page1 ul{
  font-size: 16px;
  text-align: left;
  margin: 16px auto;
  color: #333;
  flex-wrap: wrap;
}

.page1 ul>li{
  margin: 8px 0;
    line-height: 32px;
    font-size: 16px;
}
.columns2Box{
  display: flex;
  justify-content:  center;
}
.page1 ul.columns2{
 width: 100%;
}

.page1 ul.columns2>li{
  width: 100%;
  display: flex;
  justify-content: flex-around;
}
.page1 ul.columns2>li a{
  width: 50%;
  text-align: center;
}
.clear{
  clear: both;
}
.page1 p{
  font-size: 16px;
  line-height: 32px;
  text-align: justify;
}

.line{
  text-decoration:line-through;
}

.page2{
  background-color: #f8f8f8;
}

.footer{
  height: 56px;
  text-align: center;
  line-height: 56px;
  font-size: 14px;
  color: #ccc;
  background-color: #333;
}

.bannerbox{
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("./image/banner.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  color: #fff;
}

.bannerbox h1{
  margin: 0 0 80px 0;
  padding-top: calc(50vh - 120px);
  font-size: 60px;
  font-weight: 400;
}

.bannerbox p{
  margin: 24px;
  font-weight: 300;
  color: #ccc;
}

.timeline{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.timedot{
  width: 9px;
  height: 9px;
  margin: 4px 40px;
  border-radius: 9px;
  background-color: #888;
  position: relative;
}

.timedot::after{
  position: absolute;
  top: 20px;
  left: 4px;
  content: '';
  width: 1px;
  height: 40px;
  background-color: #aaa;
}

.timeline:last-child{
  margin-bottom: 0;
}

.timeline:last-child>.timedot::after{
  width: 0;
  height: 0;
}

.timetime{
  color: #888;
  width: 40%;
  text-align: left;
  font-size: 14px;
}

.timevalue{
  color: #666;
  width: 40%;
  text-align: right;
}

.zk{
  width: 20px;
  display: none;
}



@media screen and (max-width: 1000px) {
    .page1 ol.olmedia>li{
      width: 100%;
    }
    .page1 ul.columns2>li{
      width: 46%;
    }
    .header .leftBox{
      display: none;
    }
}

@media screen and (max-width: 750px) {
    .page1 ul.columns2>li{
      width: 100%;
    }
    .timetimem, .timevalue{
      width: 20%;
    }
    .page1{
      padding: 60px 20px 80px 20px;
    }
    .zk{
      display: block;
    }
    .page1 h1{
      font-size: 24px;
    }
    .nav{
      width: 180px;
      text-align: center;
      position: fixed; 
      left: 0;
      top: 60px;
      box-shadow: 0 0 4px #999;
      overflow: hidden;
      transition: all 0.3s;
      height: calc(100vh - 60px);
      display: block;
    }
    .nav.zknav{
      transform: translateX(0);
    }
    .nav.bzknav{
      transform: translateX(-100%);
    }
    .nav p {
      width: 100%;
    }
}